import React from 'react';
import { Figure } from 'react-bootstrap';

export const HaLongBay = {
    "name": "Ha Long Bay",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380382311_c36bfa961b_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380382311_8fc07afb20_o.jpg",
    "caption": "Bay Of The Descending Dragon",

    "brief": "Ha Long, meaning \"Descending Dragon\", is perhaps the most famous place in all of Vietnam. Declared a UNESCO World Heritage Site in 1994, the bay displays an impressive 1,600 limestone islands and islets, most of which are uninhabited and unaffected by humans, forming a spectacular seascape guaranteed to leave you in awe. Besides the outstanding scenery, Ha Long Bay features a great biological interest.",

    "description": <p>
                According to popular legends, when Vietnam was about to be invaded, two dragons descended from heaven to defend the Viet people from the invaders, spraying fire and jade or emeralds. With the passing of time, these jewels formed the towering limestones that today are spread all over Ha Long Bay.
                <br/><br/>
                Every year, millions of tourists are drawn to Vietnam with a unique objective, to navigate along the many karsts of Ha Long Bay. Truly, it is a marvelous scenery that must be witness first hand, and is sure to be one of those places you never forget. The best way to experience this gem is with a cruise, and there are many, many options to choose from. They range from 2 to 3 days, and can be pre-booked online (recommended) through <a href="https://www.halongbaytours.com/" target="_blank" rel="noopener noreferrer">HaLongBay Tours</a>.
                <br/><br/>
                Ha Long Bay was nominated a UNESCO World Heritage Site in 1994, due to it's outstanding beauty and biological interest. It is also featured as one of the 7 Natural Wonders of the World according to <a href="https://nature.new7wonders.com/wonders/" target="_blank" rel="noopener noreferrer">New7Wonders Nature</a>.
                <br/><br/>
                If by any chance, you want to have an experience similar to Ha Long Bay, but far away from the crowds it draws, consider also visiting either Bai Tu Long Bay or Lan Ha Bay.
            </p>,

    "nrDays": "Your stay in Ha Long Bay will mainly depend on how many days you cruise will take, which in most cases can either be 2 or 3 days.",

    "whenVisit":
        <p>
        Being close to Hanoi, it shares a similar weather. This means that there are two good times to visit the bay, from <b>September to November</b>, and from <b>March to May</b>. Beware that both periods are considered peak season, when the bay is packed with tourists and the prices are inflated.
        <br/><br/>
        A good option for those on a budget, and that want experience a more natural Ha Long Bay without the crowds of tourists, is visiting from <b>December to February</b>. The weather will be a little bit cold, but you have much of the bay for yourself, and a little breeze can help with relaxing.
        <br/><br/>
        You should avoid the months of June to August, since they see sporadic hurricanes and storms, and can result in delays or cancellation of your trip.
        </p>,

    "getHere": <p>
        Most of the cruises operated on Ha Long Bay include, either as part of the initial package or for an extra fee, transportation from your accommodation in Hanoi to the bay. This is good option if you do not want to worry about arriving late and missing the departure of your cruise.
        <br/><br/>
        For those who wish to get there on their own, the best options are either by bus or private transfer. You can check the bus schedules at <a href="https://north-vietnam.com/vietnam-bus-schedule-timetable-bus-tickets/" target="_blank" rel="noopener noreferrer">Northern Vietnam</a>.
    </p>,

    "activities": {
        "description":
            <div>
            <p>
            Without a doubt, the best way to experience Ha Long Bay is by booking with a cruise. There are so many options to choose from that it can become overwhelming, and coming down with the best one is not an easy task.
            <br/><br/>
            For the 2 days cruises, you will spend most of the time on the boat, admiring the beauty of the bay, relaxing and enjoying your time there. Some cruises also visit the other bays (Bai Tu Long Bay or Lan Ha Bay). In terms of activities, they usually fall into:
            </p>
                <ul>
                    <li>Kayaking, swimming and snorkeling on the bay</li>
                    <li>Touring fishing villages (floating islands where people live)</li>
                    <li>Exploring caves on the bay (Surprise Cave is the most famous)</li>
                    <li>Visiting Ti Top Island, known to have one of the best viewpoints of the whole bay</li>
                    <li>Visiting other islands in the bay (Monkey Island, Titov Island, etc)</li>
                </ul>
            <p>
            Your cruise will have 2 activities from the above list, and it's important you choose the one that has the activities you want to do. Additionally, all cruises seem to now include a cooking class, where you learn to make some typical Vietnamese dishes, and squid fishing at night.
            For 3 days cruises, they usually include 3 activities from the above list. Also, some of the cruises include a day in Cat Ba island, the largest island in the bay.
            <br/><br/>
            What distinguishes the many cruises apart from the itinerary (that as you see, is very similar), is the quality of the boat, the accommodation and the food. Other thing you may want to have into account is the number of maximum passengers a cruise can transport, since the fewer the people, the more you will have the bay for yourself.
            <br/><br/>
            You can check all the cruises operating on the bay by visiting <a href="https://www.halongbaytours.com/" target="_blank" rel="noopener noreferrer">HaLongBay Tours</a>.
            </p>
            <Figure.Image style={{ marginTop: "20px" }} width="100%" src="https://live.staticflickr.com/65535/50481346343_23b1db8103_o.jpg" alt="Ha Long Bay" fluid />
            <Figure.Caption className="text-center">Ha Long Bay View from Ti Top Island.</Figure.Caption>
            </div>,
        "activities": []
    },

    "food": {
        "description": "Ha Long Bay is known for serving fresh seafood, and it's perhaps the best place in Vietnam for those that love this type of food. You will find a lot of places with crabs, oysters, lobsters and many more on their menu. Of course, there are other options on the menu. Here we suggest some of the best restaurants on the bay.",

        "restaurants": [
            {
                "name": "Cua Vang Restaurant",
                "description": "A large and famous restaurant on the bay, the specialty of the house is sea crab and lobsters. They serve them in a completely different style from what you are used to, and it gives a unique and new flavor to the dishes. It's a place you try for some seafood.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50482061936_5446394fe3_o.jpg"
            },
            {
                "name": "Avocado Restaurant",
                "description": "With a menu selection from Vietnamese and European food, this is an eco-friendly restaurant with a modern design, and has a very good quality of food and service. ",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50482207452_3738dd10d0_o.jpg"
            }
        ]
    },

    "stay": {
        "description": "For those that want to enjoy more time on bay, you can base yourself in one of the many inhabited islands and from there experience various single day cruises, this way you can explore more of the bay and at the pace you wish. We leave here some suggestions for good places to stay.",

        "accomodations": [
            {
                "name": "Hostal Secret Garden",
                "image": "https://live.staticflickr.com/65535/50484592812_42236ce705_o.jpg",
                "description": "The best option to stay on Cat Ba island for those on a budget. With large and clean dorms, and great common area with a good atmosphere where you can meet other like-minded travelers. Includes a restaurant that serves food for cheap prices. There's not many reasons for not recommending this place. ",
                "link": ""
            },
            {
                "name": "Thanh Trung Hotel",
                "image": "https://live.staticflickr.com/65535/50484592792_95ab94be74_o.jpg",
                "description": "Another good option on Cat Ba Island. Located right in the small town centre of the island, you wll be close to all the action. This accommodation also posses a restaurant with good prices, and you can have breakfast included for $1.",
                "link": ""
            },
            {
                "name": "Monkey Island Resort",
                "image": "https://live.staticflickr.com/65535/50484442271_b070901745_o.jpg",
                "description": "This resort located on Monkey Island is a dream come true. You will have a bungalow on a almost private beach, with most of island for yourself. Monkey Island, as the name suggest, has a significant population of monkeys you can take photos with, feed, or play with. These monkeys are closely monitored by Vietnamese institutions to guarantee they are free of any diseases.",
                "link": ""
            },
            {
                "name": "Halong Paradise Suites Hotel",
                "image": "https://live.staticflickr.com/65535/50484592737_545225a96d_o.jpg",
                "description": "Within a 3 minute walk from the beach, this luxurious resort located on the shores of the city includes all facilities you expect from this high-end resorts. A restaurant with a very good Vietnamese and international cuisine, and swimming pool and spa with extensive therapies and treatments enraised on the local culture.",
                "link": ""
            }
        ]
    }
}