import React from 'react';
import NatureGuide from '../../../../components/guides/NatureComponent';
import Footer from '../../../../components/FooterComponent';

import { HaLongBay } from '../../../../guides/countries/vietnam/ha-long-bay';

function HaLongBayPage(props) {
    return (
        <div>
            <NatureGuide nature={ HaLongBay } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default HaLongBayPage;
